.cat-info {
    text-align: center;
    h1 {
        padding-bottom: 20px;
    }
}
.category-details {
    &.container {
        padding: 40px 24px;
    }
    .close-filter {
        display: none;
    }
}

.category-products {
    .products-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
    }
    .filter-icon {
        visibility: hidden;
    }
}
.single-product {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px 0px;
    background: #fff;
    border-radius: 16px;
    padding: 16px;
    cursor: pointer;
    img {
        width: 100%;
        &.product-image {
            height: 220px;
            object-fit: contain;
            margin-bottom: 8px;
        }
    }
    .name-info {
        h3 {
            font-size: 16px;
            font-weight: 600;
        }
        .varient {
            background: #e8f4ff;
            padding: 4px 8px;
            border-radius: 100px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .price {
        font-size: 16px;
        font-weight: 700;
        margin-top: 8px;
    }
}

.filter-with-products {
    .brand-and-category {
        background: #ffffff;
        padding: 16px 20px;
        .custom-chekbox {
            max-height: 310px;
            overflow-y: auto;
            padding-right: 8px;
            &:hover {
                &::-webkit-scrollbar {
                    width: 10px;
                }
            }
        }
    }
    .filter-items {
        width: 210px;
        min-width: 210px;
        .custom-chekbox {
            .form-group {
                margin-bottom: 2px;
            }
        }
    }
    .category-products {
        padding-left: 24px;
        width: 100%;
        .category-product-search {
            margin-top: 10px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .category-products {
        .products-row {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 767px) {
    .category-details {
        .close-filter {
            display: block;
        }
        &.container {
            padding: 20px;
        }
    }
    .category-products {
        padding-left: 0 !important;
        .products-row {
            grid-template-columns: repeat(2, 1fr);
        }
        .search-and-sort {
            position: relative;
        }
        .filter-icon {
            visibility: visible;
            position: absolute;
            left: 0;
            top: -5px;
        }
    }
    .filter-with-products {
        .filter-items {
            display: none;
            position: fixed;
            background: #fff;
            left: 0;
            top: 69px;
            width: 100%;
            z-index: 1;
            padding: 20px;
            height: calc(100vh - 69px);
            overflow-y: auto;
            &.show {
                display: block;
            }
            .close-filter {
                position: absolute;
                right: 20px;
                top: 12px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .category-products {
        .products-row {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 480px) {
    .category-products {
        .products-row {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
