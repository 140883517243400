.product-details-info {
  display: flex;
  .images {
    width: 50%;
    .product-image-slider {
      // background: #eafaff;
      padding: 0 4px 40px;
    }
  }
  .information {
    width: 50%;
    padding: 0 40px 0 40px;
    .price {
      font-size: 28px;
      line-height: 28px;
      del {
        font-size: 16px;
        color: #222222;
      }
    }
    .varient-row {
      .varients {
        display: flex;
        flex-wrap: wrap;
        .single-varient {
          padding: 12px 16px;
          border: 2px solid #868686;
          margin-right: 12px;
          border-radius: 10px;
          text-align: center;
          min-width: 120px;
          cursor: pointer;
          h4 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 5px;
          }
          p {
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
          }
          img {
            width: 18px;
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            border: 2px solid #4caf50;
          }
          &.active {
            border: 2px solid #4caf50 !important;
            background: #dbffe5;
          }
        }
      }
    }
    .colors {
      .single-color {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          .color-icon {
            outline: 2px solid #4caf50;
            border: 1px solid #fff;
          }
          p {
            color: #222222;
            font-weight: 600;
          }
        }
      }
    }
    .extra-info {
      border-top: 1px solid #e4e7eb;
      padding-top: 16px;
    }
    &.sell-type {
      button {
        &.submit {
          background: #4caf50;
          border: 1px solid #4caf50;
        }
      }
      .varient-row {
        .varients {
          .single-varient {
            min-height: unset;
            min-width: unset;
            padding: 8px 16px;
            border: 2px solid #d3d2d2;
            margin-right: 8px;
            margin-bottom: 4px;
            h4 {
              margin-bottom: 0;
              font-size: 12px;
              line-height: 16px;
              color: #6a7181;
            }
            &.active {
              h4 {
                color: #4caf50;
              }
            }
          }
        }
      }
    }
    .grade-info {
      .grade {
        font-weight: 600;
        background: #4caf50;
        padding: 0 8px;
        color: #ffffff;
        display: inline-block;
        cursor: help;
      }
    }
  }
}

.available-colors {
  margin-top: 16px;
}
.single-color {
  cursor: pointer;
  p {
    font-size: 10px;
    line-height: 10px;
    color: #6a7181;
  }
}

.delivery-info {
  background: #e4e7eb;
  margin-top: 30px;
}

.product-details-info {
  .product-details-slider {
    .slick-slide {
      img {
        max-height: 440px;
        object-fit: contain;
        object-position: center;
        margin: 0 auto;
        &.smallImage {
          width: 100%;
          max-height: 440px;
          object-fit: contain;
        }
        &.largeImage {
          width: 1200px;
          height: 1400px;
          max-height: 1400px;
          object-fit: contain;
        }
      }
    }
    .slick-dots {
      bottom: -65px;
      li {
        width: 60px;
        min-width: 60px;
        height: 60px;
      }
      img {
        width: 60px;
        min-width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .product-details-info {
    .images {
      .product-image-slider {
        padding-left: 12px;
      }
    }
    .information {
      padding: 100px 0 0 0;
      .price {
        font-size: 24px;
        line-height: 24px;
      }
      .varient-row {
        .varients {
          flex-wrap: wrap;
          .single-varient {
            padding: 8px 12px;
            min-width: 100px;
            min-height: 60px;
          }
        }
      }
    }
  }
}

// compare css
.product-image-color-info {
  .product-details-slider {
    max-width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-info {
    flex-wrap: wrap;

    .images {
      width: 100%;
      .product-image-slider {
        padding: 0;
      }
    }
    .information {
      width: 100%;
      .price {
        font-size: 20px;
        line-height: 20px;
      }
      .varient-row {
        .varients {
          .single-varient {
            margin-bottom: 8px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .delivery-info {
    margin-top: 20px;
    .row {
      padding: 32px 0;
      .col-md-4 {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
