.all-review-products {
    .single-review-product {
        margin-bottom: 12px;
        .image-info {
            width: 25%;
            img {
                width: 100px;
                max-height: 100px;
                object-fit: contain;
            }
            .info-description {
                margin-left: 12px;
            }
        }
        .rating {
            width: 20%;
            text-align: center;
            svg {
                cursor: pointer;
            }
        }
        .file-upload {
            width: 20%;
            input {
                display: none;
            }
            label {
                padding: 4px 16px;
                border: 1px dashed #000;
                display: inline-block;
                width: auto;
                font-size: 12px;
                text-align: center;
                margin-bottom: 8px;
            }
            .uploaded-files {
                p {
                    margin-bottom: 2px;
                }
            }
        }
        .description {
            width: 25%;
            text-align: right;
            padding-right: 12px;
            button {
                margin-bottom: 16px;
            }
            textarea {
                width: 100%;
                height: 60px;
                padding: 12px;
            }
        }
        .submit {
            width: 10%;
        }
    }
}
