footer {
    background: #e4e7eb;
    .copywrite {
        text-align: center;
        p {
            padding: 12px 24px;
            border-top: 2px solid #fff;
            font-size: 12px;
            line-height: 16px;
        }
    }
    .container {
        padding: 50px 24px;
    }
    .footer-contents {
        display: flex;
        .one-third {
            width: 35%;
            .app-icons {
                img {
                    width: 100%;
                    max-width: 200px;
                    height: auto;
                    margin-bottom: 20px;
                    display: block;
                }
            }

            &:nth-child(2) {
                width: 30%;
            }
        }
        p {
            font-size: 18px;
            line-height: 28px;
        }
        ul {
            li,
            a {
                font-size: 18px;
                line-height: 32px;
                color: #222222;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .widget-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        color: #222222;
        margin-bottom: 20px;
    }
    .payments {
        margin-top: 40px;
        .images {
            img {
                width: 75%;
                height: auto;
                object-fit: contain;
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .shipping {
        margin-top: 40px;
        img {
            &.posti {
                width: 100px;
            }
        }
    }
    .social-icons {
        .widget-title {
            margin-bottom: 12px;
        }

        img {
            transition: 0.2s ease-in-out;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    footer {
        .footer-contents {
            p {
                font-size: 16px;
                line-height: 24px;
            }
            ul {
                li,
                a {
                    font-size: 16px;
                    line-height: 28px;
                }
            }

            .one-third {
                .app-icons {
                    img {
                        max-width: 120px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    footer {
        .footer-contents {
            flex-wrap: wrap;
            .one-third {
                width: 100%;
                text-align: center;
                &:nth-child(2) {
                    width: 100%;
                }
                .app-icons {
                    display: flex;
                    justify-content: center;
                    img {
                        max-width: 120px;
                        width: auto;
                        margin: 4px;
                    }
                }
            }
        }
        .payments {
            margin-top: 0;
            .images {
                justify-content: center;
                img {
                    width: 75%;
                }
            }
        }
        .widget-title {
            text-align: center;
            margin-top: 30px;
        }
    }
}
