header {
  background: #ffffff;
  position: sticky;
  z-index: 1;
  top: 0;
  border-bottom: 1px solid #e4e7eb;
  .container {
    padding: 16px 24px;
  }
  .down-arrow {
    display: none;
  }
  .menus {
    position: relative;
    ul {
      &.main-menu {
        display: flex;
        li {
          font-size: 16px;
          line-height: 24px;
          margin-right: 42px;
          position: relative;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            &::after {
              content: "";
              position: absolute;
              width: 1px;
              height: 20px;
              top: calc(100% + 6px);
              left: 50%;
              transform: translateX(-50%);
              background: #000000;
            }
          }
        }
      }
    }
    .sub-category-menu {
      width: 740px;
      max-width: 740px;
      background: #ffffff;
      position: absolute;
      left: 50%;
      top: 51px;
      transform: translateX(-50%);
      padding: 12px;
      display: flex;
      justify-content: space-around;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1),
        0px 2px 3px rgba(0, 0, 0, 0.04);
      .dropdown {
        button {
          background: transparent;
          border: none;
          position: relative;
          img {
            margin-bottom: 2px;
            width: 32px;
            height: 32px;
            object-fit: contain;
          }
          &.show {
            &::after {
              content: "";
              position: absolute;
              width: 1px;
              height: 8px;
              top: calc(100% + 4px);
              left: 50%;
              transform: translateX(-50%);
              background: #000000;
            }
          }
        }
        .dropdown-menu {
          min-width: 172px;
          border: none;
          border-radius: 0px;
          top: 72px !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1),
            0px 2px 3px rgba(0, 0, 0, 0.04);
          li {
            font-size: 16px;
            line-height: 24px;
            padding: 10px 10px 10px 40px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .extra-menus {
    ul {
      display: flex;
      align-items: center;
      li {
        margin-left: 18px;
        &:first-child {
          margin-left: 0;
        }
        .cart {
          position: relative;
          .cart-count {
            position: absolute;
            right: -14px;
            top: -8px;
            font-size: 10px;
            line-height: 10px;
            background: #222222;
            color: #ffffff;
            border-radius: 100px;
            text-align: center;
            padding: 4px 7px;
            display: inline-block;
          }
        }
        .user-name {
          background: #222222;
          color: #ffffff;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
          display: inline-block;
          text-transform: uppercase;
          height: 24px;
          width: 24px;
          border-radius: 100px;
          text-align: center;
          margin-top: 2px;
        }
      }
    }
    .profile-dropdown-menu {
      border-radius: 0;
      border: none;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1),
        0px 2px 3px rgba(0, 0, 0, 0.04);
    }
    .user-other-dropdown {
      display: block;
      li {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  header {
    .menus {
      ul {
        &.main-menu {
          li {
            font-size: 14px;
            line-height: 20px;
            margin-right: 20px;
          }
        }
      }
      .sub-category-menu {
        top: 68px;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        position: fixed;
        .dropdown {
          width: 100%;
          button {
            padding: 6px 8px;
            font-weight: 500;
            position: relative;
            width: 100%;
            text-align: left;
            img {
              &.cat-image {
                display: none;
              }
              &.down-arrow {
                display: block;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
              }
            }
            &.show {
              &::after {
                display: none;
              }
            }
          }
          .dropdown-menu {
            position: unset !important;
            transform: none !important;
            box-shadow: none !important;
            padding-top: 0;
            li {
              padding: 4px 24px;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .extra-menus {
      ul {
        li {
          margin-left: 12px;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  header {
    .container {
      padding: 16px 20px;
    }
    .logo {
    }
  }
}

@media only screen and (max-width: 480px) {
  header {
    .menus {
      ul {
        &.main-menu {
          li {
            margin-right: 15px;
          }
        }
      }
    }
    .container {
      padding: 16px;
    }
    .logo {
      height: 24px;
    }
  }
}
