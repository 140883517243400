.team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.single-member {
    background: #ffffff;
    text-align: center;
    padding: 24px 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px 0px;
    transition: 0.2s;
    width: 31%;
    margin: 1%;
    p {
        font-size: 13px;
        line-height: 18px;
    }
    .photo {
        img {
            width: 180px;
            height: 180px;
        }
    }
    .name {
        font-size: 18px;
        font-weight: 600;
        margin-top: 16px;
    }
    .designation {
        font-size: 14px;
        margin-top: 4px;
    }
    .department {
        font-size: 14px;
        margin-bottom: 4px;
        margin-top: 2px;
    }
    .icons {
        margin-top: 12px;
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0 8px;
            }
        }
    }
    &:hover {
        transform: scale(1.08);
    }
}

@media only screen and (max-width: 767px) {
    .single-member {
        width: 100%;
        margin-bottom: 12px;
    }
}
