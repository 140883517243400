.profile-layout {
    display: flex;
    .leftbar {
        margin-right: 24px;
        min-width: 160px;
        .profile-menu {
            background: #fff;
            border: 1px solid #e1e8ee;
            li {
                line-height: 32px;
                cursor: pointer;
                padding: 5px 16px;
                font-size: 14px;
                border-bottom: 1px solid #e1e8ee;
                &:last-child {
                    border-bottom: none;
                }
                &.active {
                    font-weight: 600;
                    background: #edf0f2;
                }
            }
        }
    }
    .right-content {
        width: calc(100% - 136px);
        button {
            .plus {
                position: relative;
                top: 2px;
            }
        }
    }
}

//Personal information
.personal-information {
    background: #ffffff;
    padding: 20px;
    border: 1px solid #e1e8ee;
}

// Address
.address-container {
    max-width: 860px;
    margin: 0 auto;
    .address-row {
        background: #ffffff;
        border-radius: 8px;
    }
}

.both-addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.address-book-content {
    background: #fff;
    width: 100%;
    padding: 20px;
    .address-box {
        border: 1px solid #e1e8ee;
        padding: 12px 16px;
        width: 100%;
        min-height: 126px;
    }
    .single-address {
        margin-bottom: 16px;
        cursor: pointer;
        &.active {
            .address-box {
                background: #dbffe5;
            }
        }
        .edit,
        .delete {
            visibility: hidden;
        }
        &:hover {
            .edit,
            .delete {
                visibility: visible;
            }
        }
    }
}

//Address modal

.add-address-modal {
    .modal-dialog {
        width: 100%;
        max-width: 720px;
    }
}

// Order
.order-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .single-order {
        padding: 20px 24px;
        background: #fff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.04);
        .order-header {
            padding-bottom: 8px;
            border-bottom: 1px solid #e1e8ee;
            .status {
                font-size: 12px;
                padding: 4px 12px;
                background: #fbd9c2;
                color: #222222;
                border-radius: 2px;
                text-transform: uppercase;
            }
        }
        .order-body {
            position: relative;
            .serial {
                position: absolute;
                left: 0;
                top: 8px;
                p {
                    color: #fff;
                    background: #222222;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 4px 14px;
                    display: inline-block;
                }
            }
            .prodict-list {
                .product {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 0;
                    border-bottom: 1px solid #ebf0f5;
                    &:last-child {
                        border-bottom: none;
                    }
                    .quantity {
                        width: 20%;
                    }
                    .amount {
                        width: 15%;
                    }

                    .photo {
                        width: 65%;
                        padding-left: 50px;
                        img {
                            max-height: 56px;
                            width: 56px;
                            object-fit: contain;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}

//Faq css
.faq-questions {
    .accordion {
        --bs-accordion-active-color: #222222;
        --bs-accordion-btn-active-icon: url("../../../../public/icons/download.svg");
        --bs-accordion-bg: transparent;
        .accordion-item {
            border: none;
            border-bottom: 1px solid #e1e8ee;
        }
        .accordion-button {
            background-color: #ebf0f5;
            box-shadow: none !important;
            font-weight: 500;
            &:focus {
                box-shadow: none !important;
            }
            &.collapsed {
                background: transparent !important;
            }
        }
        .accordion-body {
            background: #fff;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .profile-layout {
        flex-wrap: wrap;
        .leftbar {
            width: 100%;
            margin-right: 0;
            .profile-menu {
                display: flex;
                padding-left: 16px;
                li {
                    margin-right: 24px;
                    padding: 6px 0;
                    &.active {
                        border-bottom: 2px solid #222222;
                        background: #ffffff;
                    }
                }
            }
        }
        .right-content {
            width: 100%;
            margin-top: 12px;
        }
        .both-addresses {
            grid-template-columns: 1fr;
        }
    }
    .order-list {
        .single-order {
            .order-body {
                padding-top: 32px;
                .serial {
                    top: 0px;
                }
                .prodict-list {
                    .product {
                        .photo {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
