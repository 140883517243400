@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
    padding: 0;
    margin: 0;
}
body {
    font-family: "Poppins", sans-serif !important;
    background: #f5f5f5 !important;
    padding: 0;
    margin: 0;
    a {
        text-decoration: none;
        color: hsl(122, 39%, 49%);
        &:hover {
            color: #3fe344 !important;
        }
    }
    ul {
        text-decoration: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        color: #222222;
    }
    p {
        font-size: 16px;
        line-height: 24px;
    }
    .container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
    .section-padding {
        padding: 40px 24px;
    }
    img {
        max-width: 100%;
    }
    input,
    select,
    textarea {
        border-radius: 2px !important;
        resize: unset;
        font-size: 14px !important;
        line-height: 20px;
    }
    label {
        color: #6a7181;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        margin-bottom: 4px;
    }
    .form-control,
    .form-select {
        &:focus {
            box-shadow: none;
            border: 1px solid #222222;
        }
    }
    .slick-prev,
    .slick-next {
        height: 24px;
        width: 24px;
        background: #fff;
        border-radius: 100px;
        &:hover,
        &:focus {
            background: #fff;
        }
        &::before {
            content: url("../../../public/icons/arrow-slider.svg");
            opacity: 1;
            font-size: 12px;
            border-radius: 100px;
        }
    }
    .slick-prev {
        transform: rotate(180deg);
        left: -30px;
    }
    .slick-next {
        right: -30px;
    }
    .no-shadow-react-select {
        .css-13cymwt-control {
            border: none !important;
            border-radius: 0px;
            border: 1px solid #c7ced4 !important;
            min-height: 35px;
            max-height: 35px;
        }
        .css-1dimb5e-singleValue {
            font-size: 14px !important;
            margin-top: -2px;
        }
        .css-3iigni-container {
            max-height: 35px;
        }
        .css-1jqq78o-placeholder {
            font-size: 14px !important;
            margin-top: -2px;
        }
        .css-16xfy0z-control,
        .css-t3ipsp-control {
            border-radius: 0px;
            min-height: 35px;
            max-height: 35px;
        }
        div.css-1pahdxg-control {
            border: none !important;
            border: 1px solid #222222 !important;
        }
        .css-t3ipsp-control {
            box-shadow: none !important;
            border: 1px solid #222222 !important;
            border-radius: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    body {
        .section-padding {
            padding: 20px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
        }
        ol {
            li {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
