// .btn-login {
//     font-size: 0.9rem;
//     letter-spacing: 0.05rem;
//     padding: 0.75rem 1rem;
// }

// .btn-google {
//     color: white !important;
//     background-color: #ea4335 !important;
// }

// .btn-facebook {
//     color: white !important;
//     background-color: #3b5998 !important;
// }

.cursor-pointer {
    cursor: pointer;
}

.body-content {
    padding: 24px;
}
.logo {
    //max-width: 240px;
    height: 45px;
}

.color-icon {
    width: 28px;
    height: 28px;
    display: inline-block;
    border-radius: 100px;
}

.search-box {
    position: relative;
    img {
        &.search {
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

p {
    &.empty-state {
        color: #6a7181;
        padding: 150px 0;
        text-align: center;
    }
}

// Custom Chekbox
.custom-chekbox {
    .form-group input[type="checkbox"] {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    .form-group label {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        color: #222222;
    }

    .form-group label:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #222222;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 3px;
    }

    .form-group input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 7px;
        width: 6px;
        height: 14px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .form-group input:checked + label:before {
        border: 2px solid #222222;
        background: #222222;
    }
}
// End Custom Chekbox

//modal
.modal {
    .modal-content {
        .modal-header {
            padding: 12px 24px;
            .modal-title {
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                color: #21242b;
            }
        }
        .modal-body {
            padding: 12px 24px;
        }
        .modal-footer {
            border-top: 0;
            padding-top: 0;
            padding: 12px 24px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .logo {
        height: 36px;
    }
}
