.register-section {
    .login-info-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }
    .card {
        max-width: 1160px;
        width: 1160px;
        overflow: hidden;
        margin: 30px;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 16px 0px;
        .card-body {
            padding: 0;
            .row {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                width: 100%;
                margin: 0;
                .login-thambnail {
                    width: 45%;
                    background: url("../../../../public/images/login.jpg");
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    min-height: 750px;
                    img {
                        visibility: hidden;
                    }
                }
                .form-info {
                    width: 55%;
                    padding-bottom: 40px;
                    .form-list {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .register-section {
        .card {
            .card-body {
                .row {
                    .login-thambnail {
                        width: 100%;
                    }
                    .form-info {
                        position: absolute;
                        width: 100%;
                        max-width: 85%;
                        background: #ffffff;
                        padding: 8px 24px 28px 24px;
                        border-radius: 4px;
                        .form-list {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .register-section {
        .card {
            margin: 16px;
            .card-body {
                .row {
                    .form-info {
                        width: 100%;
                        max-width: 90%;
                        background: #ffffff;
                        padding: 4px 16px 24px 16px;
                    }
                }
            }
        }
    }
}
