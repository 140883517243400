.cart-page {
    .shopping-cart {
        margin: 12px auto;
        background: #ffffff;
        box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        display: flex;
        flex-direction: column;
    }

    .title {
        height: 60px;
        border-bottom: 1px solid #e1e8ee;
        padding: 20px 30px;
        color: #5e6977;
        font-size: 18px;
        font-weight: 400;
    }

    .item {
        padding: 8px 16px;
        display: flex;
        align-items: center;
    }

    .item {
        border-top: 1px solid #e1e8ee;
    }

    /* Buttons -  Delete and Like */
    .buttons {
        position: relative;
    }

    .delete-btn {
        display: inline-block;
        cursor: pointer;
        width: 18px;
        height: 17px;
        background: url("../../../../public/icons/delete-icn.svg") no-repeat center;
        margin: 0 30px 0 20px;
    }

    .quantity {
        input {
            -webkit-appearance: none;
            border: none;
            text-align: center;
            width: 32px;
            font-size: 16px;
            color: #43484d;
            font-weight: 300;
        }
    }
    button[class*="btn"] {
        width: 30px;
        height: 30px;
        background-color: #e1e8ee;
        border-radius: 6px;
        border: none;
        cursor: pointer;
    }
    /* Product Image */
    .image {
        margin-right: 20px;
        max-width: 85px;
        img {
            max-height: 85px;
            width: 85px;
            min-width: 85px;
            object-fit: contain;
        }
    }

    /* Product Description */
    .description {
        margin-right: 60px;
        width: 35%;
    }

    .description span {
        display: block;
        font-size: 14px;
        color: #43484d;
        font-weight: 400;
    }

    .description span:first-child {
        margin-bottom: 5px;
    }
    .description span:last-child {
        font-weight: 300;
        margin-top: 8px;
        color: #86939e;
    }

    /* Product Quantity */
    .quantity {
        margin-right: 60px;
    }
    .quantity input {
        -webkit-appearance: none;
        border: none;
        text-align: center;
        width: 32px;
        font-size: 16px;
        color: #43484d;
        font-weight: 300;
    }

    button[class*="btn"] {
        width: 30px;
        height: 30px;
        background-color: #e1e8ee;
        border-radius: 6px;
        border: none;
        cursor: pointer;
    }
    .minus-btn img {
        margin-bottom: 3px;
    }

    button:focus,
    input:focus {
        outline: 0;
    }

    /* Total Price */

    .total-box {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .total-box-content {
            max-width: 400px;
            width: 400px;
            border: 1px solid #e1e8ee;
            background: #fff;
        }
        .heading {
            padding: 12px 16px;
            border-bottom: 1px solid #e1e8ee;
        }
        .subtotal-info {
            padding: 12px 20px;
            .subtotal-item {
                display: flex;
                justify-content: space-between;
                padding: 8px 16px;
                border-bottom: 1px solid #e1e8ee;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .cart-page {
        .title {
            height: unset;
            padding: 12px 16px;
            font-size: 16px;
        }
        .delete-btn {
            margin: 0 4px 0 0;
        }
        .image {
            margin-right: 12px;
            img {
                width: 80px;
            }
        }
        .description {
            margin-right: 12px;
            span {
                &:first-child {
                    margin-bottom: 0;
                }
                &:last-child {
                    margin-top: 0;
                }
            }
        }
        .quantity {
            margin-right: 12px;
            display: flex;
            align-items: center;
        }
        .total-box {
            flex-wrap: wrap;
            margin-top: 12px;
            .shopping {
                margin-bottom: 20px;
            }
        }
        .total-price {
            font-size: 14px;
        }

        button[class*="btn"] {
            width: 24px;
            height: 24px;
        }
    }
}

@media only screen and (max-width: 520px) {
    .cart-page {
        .description {
            span {
                font-size: 12px;
            }
        }
        .total-price {
            font-size: 12px;
        }
    }
}
