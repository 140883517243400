.best-selling-slider-section {
    .product-slider {
        .slick-slide {
            padding: 0 10px;
        }
        .single-product {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 16px 0px;
            text-align: center;
            h3 {
                margin-top: 8px;
                margin-bottom: 2px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
            }
            p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                del {
                    color: #6a7181;
                    font-weight: 500;
                }
            }
            img {
                height: 130px;
                width: auto;
                object-fit: contain;
                margin: 0 auto;
            }
        }
    }
}
.main-category-section {
    .container {
        .catergory-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 20px;
            .single-category {
                cursor: pointer;
                transition: 0.2s;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                align-items: center;
                background: #fff;
                &:hover {
                    scale: 1.05;
                }
            }
            .image {
                width: 45%;
                min-height: 172px;
                background: #d9d9d9;
                aspect-ratio: 1 / 1;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .info {
                background: #fff;
                padding: 20px;
                width: 55%;
            }
        }
    }
}
.super-offer-section {
    .container {
        padding: 20px 24px 40px 24px;
        .heading {
            padding-bottom: 20px;
        }
        .offers-view {
            .one-half {
                width: calc(50% - 10px);
                &:first-child {
                    background: #d9d9d9;
                }
                .grid-items {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 20px;
                    .single-item {
                        aspect-ratio: 1 / 1;
                        background: #d9d9d9;
                    }
                }
            }
            img {
                aspect-ratio: 1 / 1;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.catergory-slider-section {
    .heading {
        ul {
            li {
                margin-left: 12px;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;
                &.active {
                    font-size: 28px;
                }
            }
        }
    }
    .top-categories {
        .top-categories-first {
            aspect-ratio: 18 / 25;
            background: #d9d9d9;
            width: 360px;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .top-categories-others {
            // aspect-ratio: 18 / 28;
            // background: #D9D9D9;
            width: calc(100% - 380px);
        }
    }
}

.category-slider {
    .single-item {
        background: #d9d9d9;
        aspect-ratio: 1 / 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .slick-slider {
        .slick-list {
            padding: 0px 60px 0 0 !important;
            .slick-track {
                .slick-slide {
                    .single-item {
                        h3 {
                            font-size: 20px;
                            text-align: center;
                            padding-top: 140px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
    .slick-slide {
        padding: 0 10px;
    }
}

.cusomer-review-slider {
    .slick-slider {
        img {
            display: inline-block;
        }
        // .single-item {
        //     position: relative;
        //     padding: 40px 60px;
        //     &::after {
        //         content: "";
        //         position: absolute;
        //         width: 60%;
        //         height: 100%;
        //         background: #fff;
        //         left: 50%;
        //         transform: translateX(-50%);
        //         top: 0;
        //         z-index: -1;
        //     }
        // }
        .slick-slide {
            padding: 0 10px;
        }
        .single-item {
            background: #ffffff;
            padding: 16px;
            .stars {
                margin-bottom: 8px;
            }
            h3 {
                margin-bottom: 4px;
            }
            .author {
                margin-top: 8px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .main-category-section {
        .container {
            .catergory-row {
                grid-template-columns: 1fr;
                grid-row-gap: 16px;
            }
        }
    }
    .catergory-slider-section {
        .heading {
            ul {
                li {
                    font-size: 14px;
                    line-height: 20px;
                    &.active {
                        font-size: 20px;
                    }
                }
            }
        }
        .top-categories {
            .top-categories-first {
                width: 260px;
            }
            .top-categories-others {
                width: calc(100% - 260px);
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .catergory-slider-section {
        .top-categories {
            .top-categories-first {
                width: 200px;
            }
            .top-categories-others {
                width: calc(100% - 200px);
            }
        }
    }
    .cusomer-review-slider {
        .slick-slider {
            img {
                display: inline-block;
            }
            .single-item {
                position: relative;
                padding: 20px 0;
                &::after {
                    width: 70%;
                }
                .regular-28 {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 460px) {
    .catergory-slider-section {
        .top-categories {
            .top-categories-first {
                width: 130px;
            }
            .top-categories-others {
                width: calc(100% - 130px);
            }
        }
    }
}
