.checkout-page {
  .checkout-container {
    display: flex;
    .left-half {
      width: calc(100% - 340px);
      .contact-details {
        margin-bottom: 16px;
        .contact-details-info {
          background: #fff;
          width: 100%;
          padding: 20px;
          border-radius: 4px;
        }
      }
    }
    .right-half {
      //box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
      .confirm-order {
        padding: 20px;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        width: 320px;
        margin-left: 20px;
      }
      .promo {
        input {
          border-radius: 0 !important;
        }
        input[type="submit"] {
          background: #222222;
          color: #fff;
          padding: 4px 12px;
          border: 1px solid #222222;
          font-size: 12px;
          border-radius: 0;
          height: 35px;
        }
      }
    }
  }
  .added-cart-items {
    background: #fff;
    margin-top: 20px;
    .cart-items-info {
      width: calc(100% - 113px);
    }
    .item {
      &:first-child {
        border-top: none;
      }
      .answers {
        button {
          margin-left: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 989px) {
  .checkout-page {
    .checkout-container {
      flex-wrap: wrap;
      .left-half,
      .right-half {
        width: 100%;
      }
      .right-half {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .checkout-page {
    .checkout-container {
      .both-addresses {
        grid-template-columns: 1fr;
      }
    }
    .added-cart-items {
      .cart-items-info {
        width: calc(100% - 84px);
        justify-content: space-between;
      }
      .image {
        img {
          width: 65px;
        }
      }
      .quantity {
        p {
          white-space: nowrap;
          font-size: 12px;
          margin-right: 12px;
        }
      }
      .item {
        .answers {
          button {
            margin-left: 16px;
            font-size: 12px;
            padding: 4px 8px;
          }
        }
      }
    }
  }
}

.payment-methods {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  form {
    button {
      padding: 0 8px;
      img {
        height: 48px;
        object-fit: contain;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .checkout-page {
    .checkout-container {
      .added-cart-items {
        .image {
          img {
            width: 50px;
            min-width: 50px;
          }
        }
      }
      .right-half {
        .confirm-order {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
