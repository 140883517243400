.regular-12 {
    font-size: 12px;
    line-height: 16px;
}
.regular-14 {
    font-size: 14px;
    line-height: 20px;
}
.regular-16 {
    font-size: 16px;
    line-height: 24px;
}
.regular-20 {
    font-size: 20px;
    line-height: 28px;
}
.regular-28 {
    font-size: 28px;
    line-height: 42px;
    font-weight: 400;
}

.medium-20 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
}

.weight-500 {
    font-weight: 500;
}
.weight-600 {
    font-weight: 600;
}
.weight-700 {
    font-weight: 700;
}

.dark_01 {
    color: #222222;
}
.dark_02 {
    color: #6a7181;
}
.dark_03 {
    color: #86939e;
}

//colors
.green {
    color: #4caf50;
}
.border-top-solid {
    border-top: 1px solid #e4e7eb;
}
.border-bottom-solid {
    border-bottom: 1px solid #e4e7eb;
}

button {
    &.text-only {
        background: transparent;
        padding: 0;
        border: none;
    }
}
button {
    &.button {
        border-radius: 0;
        font-size: 14px;
        line-height: 20px;
        padding: 6px 20px;
    }
    &.green {
        background: #222222;
        color: #fff;
        border: 1px solid #222222;
        &:hover {
            background: #363535;
        }
    }
    &.secondary {
        background: transparent;
        color: #222222;
        border: 1px solid #222222;
        padding: 6px 20px;
    }
    &:disabled {
        opacity: 0.6;
    }
}

.scroll-y-label {
    scrollbar-width: thin;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.2);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
}

.scroll-x-label {
    scrollbar-width: thin;
    overflow-x: auto;
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.2);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        height: 8px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
}

@media only screen and (max-width: 767px) {
    .regular-16 {
        font-size: 14px;
        line-height: 20px;
    }
    .regular-20 {
        font-size: 16px;
        line-height: 24px;
    }
    .regular-28 {
        font-size: 24px;
        line-height: 36px;
    }
}

@media only screen and (max-width: 520px) {
    .regular-28 {
        font-size: 20px;
        line-height: 30px;
    }
    button {
        &.button {
            font-size: 12px;
            line-height: 16px;
            padding: 6px 12px;
        }
    }
}
